<template>
  <div>
      <b-notification
        v-if="isError"
        type="is-danger"
        :closable = false
        role="alert">
        <p class="is-flex is-justify-content-center is-size-4">Couldn't load Dataset: {{errorMessage}}</p>
        <br/>
        <p class="title"> Full stacktrace : </p>
        {{ errorMessage }}
        <br/>
      </b-notification>
      <div v-else>
        <card-component :title="'Dataset “' + userName + '”'" icon="table">
          <error :type='"notifications"' v-if="resetError && displayError"></error>
          <general-info :data="generalInformation"/>
          <br/>
          <data-view
            v-if="!isLoadingData"
            ref="dataview"
            :data="data"
            :showClassInfo="true"
            :showProgressInfo="true"
          ></data-view>
          <b-skeleton size="is-large" height=350  :active="isLoadingData"></b-skeleton>
          <nav class="step-navigation is-pulled-right" v-if="isMultiPage && !isLoadingData" >
              <b-pagination
                  :rounded="true"
                  :total="totalLine"
                  v-model="currentPage"
                  range-before=1
                  range-after=1
                  order="is-centered"
                  :per-page="perPage"
                  aria-next-label="Next page"
                  aria-previous-label="Previous page"
                  aria-page-label="Page"
                  aria-current-label="Current page"
                  >
              </b-pagination>
          </nav>
          <b-skeleton size="is-large" height=48 :active="isMultiPage && isLoadingData"></b-skeleton>
          <br>
        </card-component>
    </div>
  </div>
</template>

<script>
import apiService from '@/services/apiService'
import { useWorkspaceStore } from '@/stores/workspacestore'
import { useErrorStore } from '@/stores/error'

export default {
  name: 'DatasetView',
  components: {
    'data-view': () => import('@/components/rresults/DataView.vue'),
    'general-info': () => import('@/components/rresults/GeneralInfo.vue'),
    'card-component': () => import('@/components/ui/CardComponent.vue'),
    'error': () => import('@/components/ui/Error.vue')
  },
  props: {
    datasetName: {
      type: String,
      default: null
    },
    resetError: {
      type: Boolean,
      default: true
    },
    displayError: {
      type: Boolean,
      default: true
    }
  },
  setup () {
    const workspaceStore = useWorkspaceStore()
    const errorStore = useErrorStore()
    return { workspaceStore, errorStore }
  },
  data () {
    return {
      isError: false,
      errorMessage: '',
      generalInformation: {},
      data: {},
      currentPage: 1,
      totalLine: 0,
      perPage: 12,
      isLoadingAll: true,
      isLoadingData: true
    }
  },
  mounted () {
    this.getDatasetInfo()
  },
  computed: {
    isMultiPage: function () {
      return (this.totalLine > this.perPage)
    },
    getDisplayLabel: function () {
      let labels = {}
      this.generalInformation.fields.forEach(element => {
        labels[element.id] = element.label
      })
      return labels
    },
    datasetObject: function () {
      try {
        return this.workspaceStore.datasets.filter(dataset => dataset.object_name === this.datasetName)[0]
      } catch (error) {
        return null
      }
    },
    userName: function () {
      try {
        return this.datasetObject.user_name
      } catch (error) {
        return (this.datasetName)
      }
    }
  },
  watch: {
    currentPage: function () {
      this.isLoadingData = true
      this.getDatasetInfo()
    },
    datasetName: function () {
      this.isLoadingAll = true
      this.isLoadingData = true
      this.generalInformation = {}
      this.totalLine = 0
      if (this.currentPage === 1 && this.datasetName != null) {
        this.getDatasetInfo()
      } else {
        // déclanchera le watcher currentPage
        this.currentPage = 1
      }
    }
  },
  methods: {
    getDatasetInfo: function (type) {
      let rowMax = (this.currentPage) * this.perPage
      if ('data' in this.generalInformation && rowMax > this.generalInformation.data[0].nrow) {
        rowMax = this.generalInformation.data[0].nrow
      }
      let cend = -1
      if (this.datasetObject && this.datasetObject.meta.ncol > 200) {
        cend = 50
      }
      if (this.resetError) {
        this.errorStore.reset()
      }
      apiService
        .runRFunction({
          func_name: 'r_summary_description',
          dataset: this.datasetName,
          rstart: (this.currentPage - 1) * this.perPage + 1,
          rend: rowMax,
          cstart: 1,
          cend: cend
        })
        .then((rdata) => {
          if (!('data' in this.generalInformation)) {
            this.generalInformation = rdata.GeneralInformation
            this.totalLine = this.generalInformation.data[0].nrow
          }
          this.data = rdata.DataView
          this.isLoadingAll = false
          this.isLoadingData = false
          if (cend !== -1) {
            this.errorStore.setNotifications([{ text: 'Only the 50 first columns are displayed', type: 'message' }])
          }
        })
        .catch(() => {
          this.isLoadingAll = false
          this.isLoadingData = false
        })
    }
  }
}
</script>
<style>
.progress {
  background-color: #00d1b231;
}
.table thead th {
  border-bottom: 2px solid;
}
.step-navigation{
    padding-top: 5px;
    }
</style>
